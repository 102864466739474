const BoConfig = {
    sidebar: [
        {name:"Activities",link:"BoDashboard",icon:"fas fa-tachometer-alt"},
        {name:"Master Holiday",link:"BoHoliday",icon:"fas fa-calendar"},
        {name:"User Management", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig